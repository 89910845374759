import {IBasicPublisher, IComposer, IMinPublishers} from './brass';
import {IBasicMp3Album, IMinSeriesDetail} from './shared';
export type {IBasicPublisher, IMinPublishers};

export interface IBasicContemporaryMedia {
  id: number;
  youtube_id: string | null;
  vimeo_id: string | null;
  video_dl_url: string | null;
}

export interface IBasicContemporaryScoreNotes {
  author: string | null;
  content: string | null;
  id: number;
  programme_note: string | null;
}

export interface IBasicContemporaryPiece {
  arranger_id: number | null;
  composer_id: number;
  id: number;
  lyricist_id: number;
  title: string;
}

export interface IContemporaryPiece extends IBasicContemporaryPiece {
  arranger?: IComposer;
  composer: IComposer;
  hasCopyright: boolean;
  lyricist: IComposer;
  contemporaryMedia: IBasicContemporaryMedia;
}

export interface IBasicContemporaryPublication {
  id: number;
  issue: string;
  number: string | null;
  publication_date: string | null;
  contemporary_piece_id: number;
  contemporary_series_id: number;
}

export interface IContemporaryPublication
  extends IBasicContemporaryPublication {
  contemporaryPiece: IContemporaryPiece;
  contemporarySeries: IMinSeriesDetail;
  contemporaryScoreNotes: IBasicContemporaryScoreNotes;
}

export interface IContemporaryPublicationRow {
  pieceId: number;
  publicationId: number;
  series: string;
  getDisplayValue(): string;
}

export class ContemporaryPublicationRow implements IContemporaryPublicationRow {
  public arranger: string;
  public composer: string;
  public issue: string;
  public lyricist: string;
  public pieceId: number;
  public publicationId: number;
  public series: string;
  public title: string;
  public constructor(options: {
    arranger: string;
    composer: string;
    issue: string;
    lyricist: string;
    pieceId: number;
    publicationId: number;
    series: string;
    title: string;
  }) {
    this.arranger = options.arranger;
    this.composer = options.composer;
    this.issue = options.issue;
    this.lyricist = options.lyricist;
    this.pieceId = options.pieceId;
    this.publicationId = options.publicationId;
    this.series = options.series;
    this.title = options.title;
  }
  public getDisplayValue() {
    let rval = `${this.series} ${this.issue} - ${this.title} by ${this.lyricist} / ${this.composer}`;
    if (this.arranger) {
      rval += ` arr. ${this.arranger}`;
    }
    return rval;
  }
}

export interface IBasicContemporarySheetMusicType {
  id: number;
  name: string;
  isScoreAndParts: boolean;
  isAllParts: boolean;
  isScore: boolean;
  isSoloPart: boolean;
  isPart: boolean;
  isAdditionalPart: boolean;
}

export interface IBasicContemporarySheetMusic {
  display_order: number;
  hard_copy: number;
  high_quality: number;
  id: number;
  part: string;
  path: string; // TODO can we prevent this from being seen on the frontend
  contemporary_publication_id: number;
  contemporary_sheet_music_type_id: number;
}

export interface IContemporarySheetMusic extends IBasicContemporarySheetMusic {
  hardCopyPrice: number;
  price: number;
  contemporaryPublication: IContemporaryPublication;
  contemporarySheetMusicType: IBasicContemporarySheetMusicType;
}

export interface IContemporaryHardCopySheetMusic {
  sheetMusic: IContemporarySheetMusic;
  numCopies: number;
}

export interface IBasicContemporaryMp3 {
  artist: string;
  id: number;
  path: string; // TODO is this needed? better to not have this in a frontend payload
  price: number;
  contemporary_piece_id: number;
}

export interface IContemporaryMp3 extends IBasicContemporaryMp3 {
  albums: IBasicMp3Album[];
  contemporaryPiece: IContemporaryPiece;
}

export interface IContemporaryMp3Album extends IBasicMp3Album {
  mp3Tracks: Array<{
    trackNumber: number;
    contemporaryMp3: IContemporaryMp3;
  }>;
}

// Min definitions for grid data

export interface IMinContemporaryComposer {
  n: string; // name
}

export interface IMinContemporaryComposers {
  [id: string]: IMinContemporaryComposer;
}

export interface IMinContemporaryPiece {
  a: number; // arrangerId
  c: number; // composerId
  l: number; // lyricistId
  tt: string; // title
  v: number; // video (0 or 1)
}

export interface IMinContemporaryPieces {
  [id: string]: IMinContemporaryPiece;
}

export interface IMinContemporaryPublication {
  id: number; // id
  i: string; // issue
  no: number; // score notes
  m: number; // mp3s
  p: number; // pieceId
  s: number; // seriesId
  sh: number; // sheet music
  y: number; // year
}

export interface IMinContemporarySerie {
  n: string; // name
  p?: number; // publisherId
}

export interface IMinContemporarySeries {
  [id: string]: IMinContemporarySerie;
}

export interface IMinContemporaryData {
  c: IMinContemporaryComposers;
  dt: string; // yyyy-mm-dd hh:mm:ss
  p: IMinContemporaryPieces;
  pb: IMinContemporaryPublication[];
  s: IMinContemporarySeries;
}

export interface IMinContemporarySeriesData {
  s: IMinContemporarySeries;
  p: IMinPublishers;
}

export interface IContemporarySeriesDropdown {
  name: string;
  series: string[];
}

export interface IContemporaryRow {
  [key: string]: string | boolean | number;
  arranger: string;
  composer: string;
  issue: string;
  lyricist: string;
  mp3s: boolean;
  pieceId: number;
  publicationId: number;
  scoreNotes: boolean;
  series: string;
  sheetMusic: boolean;
  title: string;
  video: boolean;
  year: string;
}

export interface IContemporaryFilters {
  [key: string]: boolean | string;
  media: boolean;
  sheetMusic: boolean;
  series: string;
  issue: string;
  title: string;
  lyricist: string;
  year: string;
}

export type ContemporarySort =
  | 'default'
  | 'issueAsc'
  | 'issueDesc'
  | 'lyricicstAsc'
  | 'lyricistDesc'
  | 'seriesAsc'
  | 'seriesDesc'
  | 'titleAsc'
  | 'titleDesc';

export interface IMinContemporaryDetail {
  id: string | null;
  /**
   * Arranger
   */
  a: string | null;
  /**
   * Arranger ID
   */
  aid: number | null;
  /**
   * Composer
   */
  c: string | null;
  /**
   * Composer ID
   */
  cid: number | null;
  /**
   * Copyright
   */
  cr: boolean | null;
  /**
   * Digital Only
   */
  do: boolean | null;
  /**
   * Publication Date
   */
  dt: string | null;
  /**
   * Issue
   */
  i: string | null;
  /**
   * Lyricist
   */
  l: string | null;
  /**
   * Lyricist ID
   */
  lid: number | null;
  /**
   * MP3s
   */
  mp3: IContemporaryMp3[];
  /**
   * Number
   */
  n: string | null;
  /**
   * Score Notes Author
   */
  noa: string | null;
  /**
   * Score Notes Content
   */
  noc: string | null;
  /**
   * Programme Note
   */
  nop: string | null;
  /**
   * Piece ID
   */
  pid: string | null;
  /**
   * Media is from Regal Zonophone
   */
  rz: boolean;
  /**
   * Series
   */
  s: string | null;
  /**
   * Sheet Music
   */
  sh: IContemporarySheetMusic[];
  /**
   * Series ID
   */
  sid: string | null;
  /**
   * Title
   */
  tt: string | null;
  /**
   * Video Download URL
   */
  vd: string | null;
  /**
   * Vimeo Video
   */
  vm: string | null;
  /**
   * Years Before Sale
   */
  ybs: number | null;
  /**
   * Youtube Video
   */
  yt: string | null;
}
